export const API_URL =
  // "https://inspektorat.banjarkota.go.id/inspektorat_api/api";

  // export const API_URL =
  // "http://localhost/inspektorat_api/api";
  "https://inspektorat.banjarkota.go.id/inspektorat_api/api";

// export const ROOT_URL = "https://satudata.banjarnegarakab.go.id";

// export const API2_URL = "https://satudata.banjarnegarakab.go.id/satudata_api";
